import { defineNuxtRouteMiddleware, navigateTo } from "nuxt/app";
import { AppRoutes, routesConfiguration } from "@/constants/appRoutes";
import { useUserProfileStore } from "@/store/userProfileStore";

export default defineNuxtRouteMiddleware(async (to) => {
  // skip middleware on server
  if (process.server) {
    return;
  }

  const queryNonce = to.query?.nonce;
  const userProfileStore = useUserProfileStore();
  if (queryNonce && userProfileStore.acesssNonce !== queryNonce.toLowerCase()) {
    await userProfileStore.setAccessToken(queryNonce);
  }

  const result = await userProfileStore.setUserProfile(); 
  if (!result) {
    return navigateTo(AppRoutes.landing, {redirectCode: 301});
  }

  const routeConfig = routesConfiguration.find((route) => route.path === to.path);
  if (routeConfig &&
    routeConfig.featureName &&
    !userProfileStore.hasFeature(routeConfig.featureName)) {
    return navigateTo(AppRoutes.speechAnalytics, {redirectCode: 301});
  }

  if (to.query?.returnUrl) {
    if (queryNonce) {
      delete to.query.nonce;
    }
    return navigateTo(to.query.returnUrl);
  }

  return;
});